<template>
  <v-container fluid>
    <div class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getColaboradores">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
      <v-btn
        class="ma-1"
        color="primary"
        small
        to="/usuarios/nuevo"
        v-if="$root.acceso('CONTROL_USUARIOS')"
      >
        <v-icon left>mdi-account-plus</v-icon>Dar de alta nuevo empleado
      </v-btn>
    </div>

    <v-card>
      <v-data-table
        fixed-header
        :items="colaboradores"
        :headers="colaboradoresHeader"
        show-select
        class="fixed-checkbox"
        item-key="idColaborador"
        v-model="selected"
        :single-select="false"
        :calculate-widths="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="colaboradores"
            v-model="inlineFilters"
          ></table-filters>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-btn :to="`/usuarios/${item.idUsuario}`" color="primary" rounded>
            <v-icon left>mdi-pencil</v-icon>
            Editar
          </v-btn>
          <v-btn @click.stop="cambiarPasswordId = item.idUsuario" color="primary" rounded class="ml-1">
            <v-icon left>mdi-lock</v-icon>
            Cambiar contraseña
          </v-btn>
        </template>

        <template v-slot:item.fechaCreacion="{ item }">
          {{ parseDate(item.fechaCreacion, false, true, true) }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      transition="scroll-y-transition"
      max-width="400"
      :value="cambiarPasswordId"
      @input="(v) => (v === false ? (cambiarPasswordId = null) : null)"
			persistent
    >
      <CambiarPassword
        :key="cambiarPasswordId"
        :idUsuario="cambiarPasswordId"
				@close="cambiarPasswordId = null"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate } from "@/utils/index.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    CambiarPassword: () => import("../components/CambiarPassword.vue"),
    // EmpresaChip: () => import("@/components/EmpresaChip.vue"),
  },
  data() {
    return {
      loading: false,
			cambiarPasswordId: null,
      inlineFilters: {},
      colaboradores: [],
      colaboradoresHeader: [
        // { text: '', value: 'idColaborador' },
        // { text: 'Correo', value: 'Login' },
        { text: "Nombre", value: "usuario" },
        {
          text: "Acciones",
          value: "acciones",
          filterable: false,
          sortable: false,
        },
        // { text: 'Nombre Contrato', value: 'NombreContrato' },
        { text: "Correo", value: "email" },
        { text: "Teléfono", value: "telefono" },
        { text: "Fecha creación", value: "fechaCreacion", dataType: "date" },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
      selected: [],
    };
  },
  methods: {
    parseDate,
    getColaboradores() {
      this.loading = true;
      this.selected = [];

      axios({
        method: "GET",
        url: `/usuarios/`,
      }).then((res) => {
        this.colaboradores = res.data;
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getColaboradores();
  },
};
</script>